var brochures = (() => {
    const initBlock = (el) => {
        handleSearchButton(el);
        handleFilter(el);
        handleSearchButtonClick(el);
        handlePageNumber(el);
        handleButtons(el);
    };    
    
    const handleSearchButton = (el) => {
        el.querySelector("#ConveyancingArea").addEventListener("change",  () =>
            RedirectWindow(el, 1));
        el.querySelector('#SearchQueryTitle').addEventListener("change", () => {
            el.querySelector('#currentSearchQuery').value = el.querySelector('#SearchQueryTitle').value;
        });
    };

    const handleFilter = (el) => {
        el.querySelector("#ResourcesFilter").addEventListener("change", () =>
            RedirectWindow(el, 1));
    };
    
    const handleSearchButtonClick = (el) => {
        el.querySelector("#SearchButton").addEventListener("click", function () {
            RedirectWindow(el, 1);
        });
    };
    
    const handlePageNumber = (el) => {
        el.querySelectorAll("#PageNumber").forEach(function (e) {
            e.addEventListener("change", () => {
                RedirectWindow(el, e.value);
                });
        });
    };
    
    const handleButtons = (el) => {
        var resultsElement = el.querySelector('[data-brochures-results]');
        const totalPages = parseInt(el.dataset.totalPages);
        el.querySelectorAll('[data-prev-button]').forEach(prev => {
            prev.addEventListener('click', () => {
                const currentPage = parseInt(resultsElement.dataset.currentPage);
                if (prev.classList.contains('disabled') === false){
                    RedirectWindow(el, currentPage - 1);                    
                }
                if ((currentPage - 1) === 1){
                    prev.classList.add('disabled')
                }else{
                    prev.classList.remove('disabled')
                }
                el.querySelectorAll('[data-next-button]').forEach(next => {
                    if ((currentPage - 1) === totalPages){
                        next.classList.add('disabled')
                    }else{
                        next.classList.remove('disabled')
                    }                    
                });
                el.querySelectorAll('[data-page-select]').forEach(select => {
                    select.value = currentPage - 1;
                });
            });
        });
        el.querySelectorAll('[data-next-button]').forEach(next => {
            next.addEventListener('click', () => {
                const currentPage = parseInt(resultsElement.dataset.currentPage);
                if (next.classList.contains('disabled') === false){
                    RedirectWindow(el, currentPage + 1);
                }
                if ((currentPage + 1) === totalPages){
                    next.classList.add('disabled')
                }else{
                    next.classList.remove('disabled')
                }
                el.querySelectorAll('[data-prev-button]').forEach(prev => {                    
                    if ((currentPage + 1) === 1){
                        prev.classList.add('disabled')
                    }else{
                        prev.classList.remove('disabled')
                    }
                });
                el.querySelectorAll('[data-page-select]').forEach(select => {
                    select.value = currentPage + 1;
                });
            });
        });
    }

    const RedirectWindow = (el, pageNumber) => {        
        const pageId = el.dataset.pageId;
        const brochureGroupTitle = document.querySelector("#ConveyancingArea").value;
        const resourceAlias = document.querySelector("#ResourcesFilter").value;
        const searchQuery = document.querySelector("#SearchQueryTitle").value;        
        const resultsWrapper = el.querySelector('[data-brochures-results]');
        const url = `/umbraco/surface/Brochures/GetUpdatedBrochures?pageNumber=${pageNumber}&pageId=${pageId}&brochureGroupTitle=${encodeURIComponent(brochureGroupTitle)}&resourceAlias=${resourceAlias}&searchQuery=${searchQuery}`;
        fetch(url)
            .then(response => response.text())
            .then(data => {
                resultsWrapper.outerHTML = data;
                document.querySelector('#currentSearchQuery').value = searchQuery;
                ScrollToBrochureListings();
                processor.Run(resultsWrapper);
                new BrochureCookiePicker(el);
            }).then(() => {
                document.querySelectorAll('[data-brochures]').forEach(el => brochures.initBlock(el));
                document.querySelectorAll('[data-brochure-modal]').forEach(el => brochureModal.initBlock(el));
            });
    }

    const ScrollToBrochureListings = () => {
        var position = $('[data-brochures-results]').position();
        var top = position.top;
        window.scrollTo(0, top); 
    }
    
    return {initBlock};
})();

var brochureModal = (() => {
    
    const initBlock = (el) => {
        const title = el.dataset.brochureModal;
        document.querySelectorAll('[data-brochure-modal-item]').forEach(item => {
            if (item.dataset.brochureModalItem === title){
                const content = item.querySelector('[data-brochure-modal-content]');
                const toggle = el.querySelector('[data-brochure-modal-toggle]');
                if (toggle){
                    toggle.addEventListener('click', () => {
                        content.classList.add('embed-form--form-open');
                    });
                }   
                const close = content.querySelector('[data-toggle-closemodal]');
                if (close){
                    close.addEventListener('click', () => {
                        content.classList.remove('embed-form--form-open');
                    });                    
                }
                const overlay = content.querySelector(`[data-toggle-close]`);
                if (overlay){
                    overlay.addEventListener(`click`, e => {
                        if (e.target === overlay) {
                            content.classList.remove(`embed-form--form-open`);
                        }
                    });                    
                }
            }
        });
    }    
    return {initBlock};
    
})();



document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('[data-brochures]').forEach(el => brochures.initBlock(el));
    document.querySelectorAll('[data-brochure-modal]').forEach(el => brochureModal.initBlock(el));
});